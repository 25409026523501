
button {
  min-width: 75px;
}
nav {
  a {
    @apply group flex items-center px-2 py-2 text-primary leading-6 font-medium text-white focus:outline-none focus:text-primary focus:bg-white transition ease-in-out duration-150 whitespace-no-wrap;

    &:hover {
      @apply text-primary bg-gray-200;
    }

    i {
      @apply px-2;
    }
  }
}

.avatar {
  @apply border border-white shadow-md mr-3;
}

.short {
  nav {
    a {
      span {
        @apply w-full text-left px-0;
      }

      i {
        @apply hidden;
      }
    }
  }

  .if-short-btn {
    @apply px-0 mx-0;
  }

  .avatar {
    @apply m-auto;
  }
}
