@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

/* purgecss start ignore */
@import "@relocity/ui/dist/ui.css";
@import "vue-swatches/dist/vue-swatches.css";
@import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';
@import 'vue-datetime/dist/vue-datetime.css';
@import 'quill/dist/quill.core.css';
@import 'quill/dist/quill.snow.css';
@import "vue2-datepicker/index.css";
/* purgecss end ignore */


.relocity-bg {
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.25) 80px, #fff 265px), url(@/assets/bg@2x.png);
  background-size: auto, 102px;
  @apply bg-white;
}

.dropdown-option {
  @apply cursor-pointer block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900;
}

/**
datepicker styles
*/
  .vdatetime-popup__header {
    @apply bg-black;
  }
  .vdatetime-popup__actions__button,
  .vdatetime-time-picker__item--selected,
  .vdatetime-month-picker__item--selected,
  .vdatetime-year-picker__item--selected,
  .vdatetime-month-picker__item--selected {
    @apply text-black;
  }
  .vdatetime-calendar__month__day--selected > span > span,
  .vdatetime-calendar__month__day--selected:hover > span > span {
    @apply bg-black;
  }

/* vue-ui-components calendar input */
.calendar-icon {
  @apply absolute top-2 left-2;
}
.calendar-input {
  @apply pl-8;
}
.clear-date {
  @apply absolute top-2 right-4;
}

.form-group {
  @apply my-4 !important;
}


input[type="checkbox"]:not([disabled]) {
  cursor: pointer;
}

button.button--circle {
  width:35px;
  height: 35px;
  padding: 0;
}

button.button--circle i {
  @apply flex justify-center;
}

input[disabled],
textarea[disabled]
{
  background: #ededed;
  pointer-events: none;
  opacity: .6;
}

.tooltip {
  z-index: 100;
}
